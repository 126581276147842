<template>
  <div class="portal-container">
    <PortalSlider />
    <header>
      <div>
        <img src="@assets/logo.png" />
      </div>
      <nav>
        <div class="menu-container">
          <el-menu
            v-if="navMenus.length > 1"
            router
            :default-active="activeIndex"
            class="portal-menu"
            mode="horizontal"
            background-color="transparent"
            text-color="#ffffff"
            active-text-color="#ffffff"
          >
            <router-link v-for="menu in navMenus" :key="menu.to" :to="menu.to">
              <el-menu-item :index="menu.to">{{ menu.title }}</el-menu-item>
            </router-link>
          </el-menu>
        </div>
        <div v-if="isLogin" class="profile-container">
          <span>欢迎你{{ userInfo.userName ? '，' + userInfo.userName : '' }}</span>
          <el-dropdown trigger="click" @command="handleDropdown">
            <img src="@assets/avatar.jpeg" />
            <el-dropdown-menu>
              <el-dropdown-item command="modifyPwd">修改密码</el-dropdown-item>
              <el-dropdown-item command="logout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div v-if="!isLogin" class="profile-container">
          <!-- <router-link to="/user/register">
            <el-button type="primary" size="small">注册</el-button>
          </router-link> -->
          <router-link v-if="!isLogin" to="/user/login">
            <el-button class="ml-10" type="primary" size="small">登录</el-button>
          </router-link>
        </div>
      </nav>
    </header>
    <div v-show="isScorePage" class="case-list">
      <div class="case-list-title">案例列表</div>
    </div>
    <section>
      <router-view />
    </section>
    <footer>
      <ul>
        <li style="width: 170px; text-align: center">
          <h4 class="text-center">友情链接</h4>
          <a href="https://www.isc.org.cn/" target="_blank">
            <img style="width: 100px" src="@assets/bottom-logo.png" />
          </a>
        </li>
        <li style="margin-left: 90px">
          <h4>联系我们</h4>
          <p>邮箱：ISCDTD@fuxiinstitution.org</p>
          <p style="width: 210px">联系地址：北京市海淀区永泰庄北路一号东升国际科学园10号院</p>
          <p><a href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备19064960号</a></p>
        </li>
        <li>
          <h4>关注我们</h4>
          <p><img class="weixin" src="@assets/weixin.png" /></p>
        </li>
      </ul>
      <p class="footer-support">
        <span>本站技术服务由“伏羲知库”提供</span>
      </p>
    </footer>
    <el-dialog
      title="修改密码"
      :visible.sync="isDialogVisible"
      :close-on-click-modal="false"
      width="300px"
    >
      <PasswordForm />
    </el-dialog>
  </div>
</template>

<script>
import storage from 'store';
import store from '@/store';
import { ACCESS_TOKEN } from '@/store/mutation-types';
import PortalSlider from '@/components/PortalSlider/PortalSlider.vue';
import PasswordForm from '@/components/PasswordForm/PasswordForm.vue';

export default {
  name: 'PortalLayout',
  components: { PortalSlider, PasswordForm },
  data: function () {
    return {
      isLogin: false,
      isDialogVisible: false,
      navMenus: [],
      activeIndex: this.$route.path,
      userInfo: {},
      isScorePage: false,
    };
  },
  watch: {
    $route() {
      this.isShowTitle();
    },
  },
  created() {
    this.isLogin = !!storage.get(ACCESS_TOKEN);
    this.navMenus = store.getters.navRouters;
    this.userInfo = store.getters.userInfo;
    this.isShowTitle();
  },
  methods: {
    handleDropdown(command) {
      switch (command) {
        case 'toProfile':
          this.$router.push({ name: 'profile' });
          break;
        case 'logout':
          this.logout();
          break;
        case 'modifyPwd':
          this.modifyPwd();
          break;
      }
    },
    logout() {
      this.$store
        .dispatch('user/logout')
        .then(() => {
          this.$router.go(0);
        })
        .catch(() => {});
    },
    modifyPwd() {
      // TODO: 修改密码弹窗
      this.isDialogVisible = true;
    },
    isShowTitle() {
      this.isScorePage = this.$route.path === '/case/score';
    },
  },
};
</script>

<style lang="scss" scoped>
.portal-container {
  width: 100%;
  min-width: 980px;
  position: relative;

  header {
    z-index: 999;
    width: 980px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    justify-content: space-between;
    & > div {
      width: 246px;
      height: 50px;
      img {
        padding-top: 10px;
        width: 100%;
      }
    }
  }

  nav {
    flex: 1;
    display: flex;
    justify-content: space-between;
    // line-height: 36px;
  }
  .menu-container {
    flex-grow: 1;
  }

  .portal-menu {
    display: flex;
    justify-content: space-around;
    margin: 0;
    height: 50px;
    line-height: 50px;
    border-bottom: none;
    .router-link-active {
      background: #6d5edf;
    }
    ::v-deep {
      .el-menu-item:focus,
      .el-menu-item:hover {
        background-color: #6d5edf !important;
      }
    }
    .el-menu-item {
      height: 50px;
      line-height: 50px;
      font-size: 16px;
    }
  }

  .profile-container {
    padding: 10px 0;
    font-size: 14px;
    color: #fff;
    img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      vertical-align: middle;
      margin-left: 6px;
    }
  }
  section {
    width: 980px;
    margin: 0 auto;
    min-height: calc(100vh - 450px);
  }
  .ml-10 {
    margin-left: 10px;
  }
  .text-center {
    text-align: center;
  }
  footer {
    background-color: #190037;
    color: #ffffff;
    ul {
      width: 980px;
      margin: 0 auto;
      padding: 0;
      display: flex;
      justify-content: space-between;
      li {
        list-style: none;
      }
      h4 {
        margin-bottom: 26px;
        margin-top: 40px;
      }
      p {
        font-size: 12px;
        margin-bottom: 0px;
        line-height: 20px;
      }
      // .organization {
      //   margin-left: 10px;
      //   vertical-align: middle;
      //   width: 120px;
      // }
      // .logo {
      //   margin-left: 10px;
      //   vertical-align: middle;
      //   width: 120px;
      // }
      .weixin {
        width: 80px;
      }
      // .email {
      //   background: url(@assets/email.png) no-repeat center left;
      //   background-size: 24px;
      //   padding: 0 40px;
      // }
      // .address {
      //   background: url(@assets/address.png) no-repeat center left;
      //   background-size: 26px;
      //   padding: 0 40px;
      // }
    }
  }
  .footer-support {
    width: 980px;
    margin: 16px auto 0;
    padding-bottom: 10px;
    span {
      border-top: 0.5px solid #ffffff;
      padding-top: 16px;
      font-size: 12px;
    }
  }
  .case-list {
    display: flex;
    justify-content: center;
    padding: 28px 0 14px;
    border-bottom: 1px solid rgba(151, 151, 151, 0.46);
    font-size: 18px;
    line-height: 32px;
    color: #333;
    .case-list-title {
      padding-left: 43px;
      background: url(@assets/header-case.png) no-repeat left center;
      background-size: 32px 32px;
      position: relative;
      &:after {
        content: '';
        width: 120%;
        height: 9px;
        background: rgba(27, 87, 152, 0.46);
        position: absolute;
        left: -10%;
        right: 0;
        bottom: -24px;
      }
    }
  }
}

.el-dropdown-menu {
  padding: 0;
  .el-dropdown-menu__item {
    padding: 0 5px;
    min-width: 70px;
    text-align: center;
  }
}
</style>
