<template>
  <div class="login-container">
    <header>
      <router-link to="/portal/index">
        <img src="@assets/logo.png" />
      </router-link>
      <h3>2023中国互联网大会 | “互联网助力经济社会数字化转型”案例征集</h3>
    </header>
    <div class="page-main">
      <img src="@assets/title-text.png" />
      <div class="form-container">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginLayout',
};
</script>
<style lang="scss">
.login-container {
  width: 100%;
  height: 100vh;
  min-height: 780px;
  background: url(@assets/login-bg.png) no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  position: relative;

  header img {
    width: 300px;
    margin: 20px;
  }
  header h3 {
    margin-top: 0px;
    text-align: center;
    font-size: 28px;
    color: #ffffff;
  }
  .page-main {
    position: absolute;
    right: 80px;
    text-align: center;
    & > img {
      width: 80%;
    }
  }
  .form-container {
    width: 300px;
    min-height: 350px;
    background-color: rgba(0, 12, 64, 0.5);
    border-radius: 10px;
    padding: 20px;
  }
}
</style>
