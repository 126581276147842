<template>
  <swiper :options="swiperOption">
    <swiper-slide v-for="(item, index) in sliderImages" :key="index">
      <div class="slider-bg" :style="{ backgroundImage: 'url(' + item.background + ')' }">
        <a v-if="item.link" :href="item.link">
          <img class="slider-img" :src="item.imageSource" alt />
        </a>
        <img v-else class="slider-img" :src="item.imageSource" alt />
      </div>
    </swiper-slide>
    <div slot="pagination" class="swiper-pagination"></div>
  </swiper>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper';
export default {
  name: 'PortalSlider',
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      sliderImages: [
        {
          imageSource: require('@assets/banner-1.png'),
          background: require('@assets/banner-1-bg.png'),
        },
        {
          imageSource: require('@assets/banner-2.png'),
          background: require('@assets/banner-2-bg.png'),
          link: '/case/collection',
        },
      ],
      swiperOption: {
        loop: true,
        // 自动播放
        autoplay: true,
        // 切换效果
        effect: 'fade',
        // 分页器设置
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
    };
  },
};
</script>

<style scoped>
/* 轮播图 */
.slider-bg {
  width: 100%;
  height: 270px;
  text-align: center;
  /* TODO */
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 100% 270px;
}
.slider-img {
  width: 980px;
  height: 270px;
}
</style>
