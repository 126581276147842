import request from '@/utils/request';

// 获取图片验证码
export function getCaptchaImg() {
  return request({
    url: '/captchaImage',
    method: 'get',
  });
}

// 获取短信验证码
export function getSMSCode(data) {
  return request({
    url: '/sms/sendCode',
    method: 'get',
    data,
  });
}

// 注册
export function register(data) {
  return request({
    url: '/register',
    method: 'post',
    data: data,
  });
}

// 登录
export function login(data) {
  return request({
    url: '/login',
    method: 'post',
    data,
  });
}

// 退出登录
export function logout() {
  return request({
    url: '/logout',
    method: 'get',
  });
}

// 修改密码
export function updatePassword(data) {
  return request({
    url: '/updatPwdByMessage',
    method: 'put',
    data,
  });
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/system/user/userInfo',
    method: 'get',
  });
}
