import storage from 'store';
import { ACCESS_TOKEN } from '@/store/mutation-types';
import { register, login, logout, getInfo } from '@/api/login';

// initial data
const state = {
  token: storage.get(ACCESS_TOKEN) ?? '', // get initial data from storage
  roles: [],
  userInfo: {},
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token ?? '';
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles ?? [];
  },
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo ?? {};
  },
};

const actions = {
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      login(userInfo)
        .then(response => {
          const { token } = response.data;
          commit('SET_TOKEN', token);
          storage.set(ACCESS_TOKEN, token, 7 * 24 * 60 * 60 * 1000);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  register({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      register(userInfo)
        .then(response => {
          const { token } = response.data;
          commit('SET_TOKEN', token);
          storage.set(ACCESS_TOKEN, token, 7 * 24 * 60 * 60 * 1000);
          resolve(token);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getInfo()
        .then(response => {
          const data = response.data || {};
          // 服务端接口返回 userType，以此判断角色
          const { userType } = data;
          const roles = String(userType) === '1' ? ['expert'] : ['organization'];
          commit('SET_ROLES', roles);
          commit('SET_USER_INFO', data);
          resolve({ roles, userInfo: data });
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  logout({ commit }) {
    return new Promise((resolve, reject) => {
      logout()
        .then(() => {
          commit('SET_TOKEN', '');
          storage.remove(ACCESS_TOKEN);

          commit('SET_ROLES', []);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
