<template>
  <el-button size="small" class="code-btn" :disabled="count !== 0" @click="handleClick">
    {{ count === 0 ? '获取验证码' : count + '秒' }}
  </el-button>
</template>
<script>
export default {
  name: 'CodeButton',
  props: {
    countdown: {
      type: Number,
      default: 60,
    },
  },
  timer: null,
  data() {
    return {
      count: 0, // 倒计时
    };
  },
  destroyed() {
    this.timer && clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    handleClick() {
      const callback = () => {
        this.count = this.countdown;
        this.timer = setInterval(() => {
          if (this.count > 0) {
            this.count--;
          } else {
            this.count = 0;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      };
      this.$emit('sendSMS', callback);
    },
  },
};
</script>
<style lang="scss" scoped>
.code-btn {
  padding: 9px 9px;
  margin-top: 3px;
  width: 100%;
}
</style>
