import axios from 'axios';
import storage from 'store';
import store from '@/store';
import { VueAxios } from './axios';
import { ACCESS_TOKEN } from '@/store/mutation-types';
import { MessageBox, Message } from 'element-ui';

// response.data.code
const errorCode = {
  401: '认证失败，无法访问系统资源',
  403: '当前操作没有权限',
  404: '访问资源不存在',
  default: '系统未知错误，请反馈给管理员',
};

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  headers: { 'Content-Type': 'application/json; charset=utf-8' },
  timeout: 20000, // request timeout
});

request.interceptors.request.use(
  config => {
    const token = storage.get(ACCESS_TOKEN);
    // 登录后的接口都需要携带 `token` 参数
    if (config.method === 'get') {
      config.params = { ...config.data, token };
    } else {
      config.params = { token };
    }
    return config;
  },
  error => {
    // TODO: 请求前能有什么错误？
    console.error(error);
  },
);

request.interceptors.response.use(
  response => {
    // 若响应数据中未设置 code，则默认为成功状态
    const code = response.data.code || 200;
    // 错误信息
    const msg = errorCode[code] || response.data.msg || errorCode['default'];
    if (code === 401) {
      MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        store
          .dispatch('user/logout')
          .then(() => {
            location.href = '/user/login';
          })
          .catch(() => {});
      });
      return Promise.reject(new Error(msg));
    } else if (code === 999) {
      MessageBox.confirm('注册成功！待审核后开通功能', '系统提示', {
        confirmButtonText: '确定',
        showCancelButton: false,
        type: 'success',
      }).then(() => {
        location.href = '/portal/index';
      });
      return Promise.resolve(response.data || {});
    } else if (code === 997) {
      MessageBox.confirm('用户未注册，请先注册!', '系统提示', {
        confirmButtonText: '确定',
        showCancelButton: false,
        type: 'warning',
      }).then(() => {
        location.href = '/user/register';
      });
      return Promise.resolve(response.data || {});
    } else if (code !== 200) {
      Message({
        message: msg,
        type: 'error',
        duration: 5 * 1000,
      });
      return Promise.reject(new Error(msg));
    } else if (
      //  兼容blob下载出错json提示
      response.request.responseType === 'blob' &&
      response.data instanceof Blob &&
      response.data.type &&
      response.data.type.toLowerCase().indexOf('json') != -1
    ) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
          response.data = JSON.parse(reader.result);
          resolve(Promise.reject(response.data.msg));
        };

        reader.onerror = () => {
          reject(response.data.msg);
        };

        reader.readAsText(response.data);
      });
    } else {
      return response.data;
    }
  },
  error => {
    console.error('response error: ', error);
    let { message } = error;
    if (message == 'Network Error') {
      message = '后端接口连接异常';
    } else if (message.includes('timeout')) {
      message = '系统接口请求超时';
    } else if (message.includes('Request failed with status code')) {
      message = '系统接口' + message.substr(message.length - 3) + '异常';
    }
    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  },
);

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request);
  },
};

export default request;

export { installer as VueAxios, request as axios };
