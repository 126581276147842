import Vue from 'vue';
import ElementUI from 'element-ui';
import VueAwesomeSwiper from 'vue-awesome-swiper';

import '@/styles/index.scss'; // global css
import 'swiper/dist/css/swiper.css'; // swiper css

import * as filters from '@/filters/index'; // global filters
import { VueAxios } from '@/utils/request';

import store from '@/store';
import '@/router/permission'; // permission control
import router from '@/router';

import App from '@/App.vue';

Vue.use(ElementUI);
Vue.use(VueAwesomeSwiper);

Vue.config.productionTip = false;

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

// mount axios to `Vue.$http` and `this.$http`
Vue.use(VueAxios);

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
});
