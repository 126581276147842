import PortalLayout from '@/layouts/PortalLayout';
import LoginLayout from '@/layouts/LoginLayout';

export const rolePermissionMap = {
  expert: ['score'],
  organization: ['collection'],
};

// 基础路由
export const constantRouterMap = [
  {
    path: '/',
    redirect: '/portal/index',
  },
  {
    path: '/portal',
    component: PortalLayout,
    redirect: '/portal/index',
    children: [
      {
        path: 'index',
        name: 'portal',
        meta: {
          navShow: true,
          title: '首页',
        },
        component: () => import(/* webpackChunkName: "portalIndex" */ '@/views/portal/Index'),
      },
    ],
  },
  {
    path: '/user',
    component: LoginLayout,
    redirect: '/user/login',
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/user/Login'),
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "register" */ '@/views/user/Register'),
      },
      {
        path: 'findpassword',
        name: 'findpassword',
        component: () => import(/* webpackChunkName: "findpassword" */ '@/views/user/FindPassword'),
      },
    ],
  },
  // {
  //   path: '*',
  //   redirect: '/portal/index',
  // },
];

// 权限路由
export const asyncRouterMap = [
  {
    path: '/case',
    component: PortalLayout,
    redirect: '/portal/index',
    navShow: true,
    children: [
      {
        path: 'collection',
        meta: {
          permissions: ['collection'],
          navShow: true,
          title: '案例征集',
        },
        component: () =>
          import(/* webpackChunkName: "caseCollection" */ '@/views/portal/CaseCollection'),
      },
      {
        path: 'score',
        meta: {
          permissions: ['score'],
          navShow: true, // 显示在顶部导航栏
          title: '专家评分',
        },
        component: () => import(/* webpackChunkName: "expertScore" */ '@/views/portal/ExpertScore'),
      },
    ],
  },
];
