<template>
  <el-form ref="form" :model="form" status-icon :rules="rules">
    <el-form-item prop="phone">
      <el-input
        v-model="form.phone"
        type="text"
        prefix-icon="el-icon-mobile-phone"
        placeholder="请输入您的手机号码"
        auto-complete="off"
      ></el-input>
    </el-form-item>
    <el-row>
      <el-col :span="14">
        <el-form-item prop="checkCode">
          <el-input
            v-model="form.checkCode"
            type="text"
            prefix-icon="el-icon-key"
            placeholder="验证码"
            auto-complete="off"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8" :offset="2">
        <CodeButton :countdown="60" @sendSMS="getSMSCode" />
      </el-col>
    </el-row>
    <el-form-item prop="newPassword">
      <el-input
        v-model="form.newPassword"
        type="password"
        prefix-icon="el-icon-lock"
        placeholder="请输入您的新密码"
        auto-complete="new-password"
      ></el-input>
    </el-form-item>
    <el-form-item prop="confirmPassword">
      <el-input
        v-model="form.confirmPassword"
        type="password"
        prefix-icon="el-icon-lock"
        placeholder="请再次确认您的密码"
        auto-complete="new-password"
      ></el-input>
    </el-form-item>

    <el-button type="primary" class="login-btn" @click="submit">提交</el-button>
  </el-form>
</template>

<script>
import { getSMSCode, updatePassword } from '@/api/login';
import CodeButton from '@/components/CodeButton/CodeButton.vue';

export default {
  name: 'UserLogin',
  components: { CodeButton },
  data() {
    var validatePassword = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('请输入确认密码'));
      }
      if (value !== this.form.newPassword) {
        return callback(new Error('输入的密码前后不一致'));
      }
      callback();
    };
    return {
      form: {
        phone: '',
        checkCode: '',
        newPassword: '',
        confirmPassword: '',
      },
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1{1}\d{10}$/, message: '请输入合法手机号', trigger: 'blur' },
        ],
        checkCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        newPassword: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        confirmPassword: [{ validator: validatePassword, trigger: 'blur' }],
      },
    };
  },
  methods: {
    getSMSCode(callback) {
      this.$refs.form.validateField('phone', errorMessage => {
        if (!errorMessage) {
          const { phone } = this.form;
          // operationType:  '0'-注册，'1'-登录，'2'-修改密码
          getSMSCode({ phone, operationType: '2' }).then(() => {
            callback();
          });
        } else {
          return false;
        }
      });
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const values = { ...this.form, apiVist: '1' };
          delete values.confirmPassword;
          updatePassword(values)
            .then(() => {
              return this.$store.dispatch('user/logout');
            })
            .then(() => {
              this.$router.push({ name: 'login' });
            })
            .catch(() => {});
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss">
.code-btn {
  padding: 9px 9px;
  margin-top: 3px;
  width: 100%;
}
.login-btn {
  width: 100%;
}
</style>
