import { uniq, cloneDeep } from 'lodash';
import { asyncRouterMap, constantRouterMap, rolePermissionMap } from '@/router/config';

function getPermissions(roles) {
  return uniq(
    roles.reduce((prev, cur) => {
      return prev.concat(rolePermissionMap[cur]);
    }, []),
  );
}

function hasPermission(permissions, router) {
  const routePermissions = router.meta?.permissions;
  if (routePermissions) {
    let flag = false;
    for (let i = 0, len = permissions.length; i < len; i++) {
      flag = routePermissions.includes(permissions[i]);
      if (flag) {
        return true;
      }
    }
    return false;
  }
  return true;
}

function filterAsyncRouter(routerMap, permissions) {
  const accessedRouters = routerMap.filter(router => {
    if (hasPermission(permissions, router)) {
      if (router.children?.length) {
        router.children = filterAsyncRouter(router.children, permissions);
      }
      return true;
    }
    return false;
  });
  return accessedRouters;
}

function filterNavRouter(routerMap, parentPath = '') {
  const menu = [];
  routerMap.forEach(router => {
    if (router.children?.length) {
      menu.push(...filterNavRouter(router.children, router.path));
    } else if (router.meta?.navShow) {
      menu.push({
        to: parentPath + '/' + router.path,
        title: router.meta.title,
      });
    }
  });
  return menu;
}

const state = {
  accessedRouters: [],
  routers: constantRouterMap,
  navRouters: filterNavRouter(constantRouterMap),
};
const mutations = {
  SET_ROUTERS: (state, accessedRouters) => {
    state.accessedRouters = accessedRouters ?? [];
    state.routers = constantRouterMap.concat(accessedRouters ?? []);
    state.navRouters = filterNavRouter(state.routers);
  },
};
const actions = {
  generateRoutes({ commit }, roles) {
    return new Promise(resolve => {
      const routerMap = cloneDeep(asyncRouterMap);
      const permissions = getPermissions(roles);
      const accessedRouters = filterAsyncRouter(routerMap, permissions);
      commit('SET_ROUTERS', accessedRouters);
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
