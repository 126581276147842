import NProgress from 'nprogress';
import storage from 'store';
import store from '@/store';
import { ACCESS_TOKEN } from '@/store/mutation-types';
import router from './index';

NProgress.configure({ showSpinner: false });

const loginRoutePath = '/user/login';
const defaultRoutePath = '/portal/index';
const allowList = [loginRoutePath, '/user/register', '/user/findpassword', defaultRoutePath];

router.beforeEach((to, from, next) => {
  NProgress.start(); // start progress bar
  if (storage.get(ACCESS_TOKEN)) {
    // TODO: 是否需要先将 storage 与 store 中的数据同步，然后从 store 中读取？
    if (to.path === loginRoutePath) {
      next({ path: defaultRoutePath });
      NProgress.done(); // if redirection occurred, it won't emit `afterEach` hook
    } else {
      if (!store.getters.roles?.length) {
        store
          .dispatch('user/getInfo')
          .then(({ roles }) => {
            store.dispatch('permission/generateRoutes', roles).then(() => {
              store.getters.accessedRouters.forEach(r => {
                router.addRoute(r);
              });
              // 请求带有 redirect 重定向时，登录自动重定向到该地址
              const redirect = decodeURIComponent(from.query.redirect || to.path);

              const isAllowed = router.getRoutes().some(route => route.path === redirect);
              if (!isAllowed) {
                next({ path: defaultRoutePath });
              } else if (to.path === redirect) {
                // set the replace: true so the navigation will not leave a history record
                next({ ...to, replace: true });
              } else {
                next({ path: redirect });
              }
            });
          })
          .catch(err => {
            console.error(err);
            // 失败时，获取用户信息失败时，调用登出，来清空历史保留信息
            store.dispatch('user/logout').then(() => {
              next({ path: loginRoutePath, query: { redirect: to.fullPath } });
            });
          });
      } else {
        const isAllowed = router.getRoutes().some(route => route.path === to.path);
        if (!isAllowed) {
          next({ path: defaultRoutePath });
        } else {
          next();
        }
      }
    }
  } else {
    const isAllowed = allowList.some(p => to.path.startsWith(p));
    if (isAllowed) {
      next();
    } else {
      next({ path: loginRoutePath, query: { redirect: to.fullPath } });
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  NProgress.done(); // finish progress bar
});
